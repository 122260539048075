@import url('https://fonts.googleapis.com/css2?family=Open+Sans:wght@300;400;500;600;700&display=swap');

* html {
  padding: 0;
  margin: 0;
  box-sizing: border-box;
}

body {
  margin: 0;
  font-family: 'Open Sans', sans-serif;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
}

.gradient-bg-hero {
  background-color: #151c25;
  background-image: radial-gradient(
      at 0% 0%,
      hsl(302deg 25% 18%) 0,
      transparent 50%
    ),
    radial-gradient(at 50% 0%, hsl(0deg 39% 30%) 0, transparent 50%),
    radial-gradient(at 100% 0%, hsla(339, 49%, 30%, 1) 0, transparent 50%);
}

.gradient-bg-artworks {
  background-color: #0f0e13;
  background-image: radial-gradient(
      at 50% 50%,
      hsl(302deg 25% 18%) 0,
      transparent 50%
    ),
    radial-gradient(at 0% 0%, hsla(253, 16%, 7%, 1) 0, transparent 50%),
    radial-gradient(at 50% 50%, hsla(339, 39%, 25%, 1) 0, transparent 50%);
}

.gradient-bg-footer {
  background-color: #151c25;
  background-image: radial-gradient(
      at 0% 100%,
      hsl(0deg 39% 30%) 0,
      transparent 53%
    ),
    radial-gradient(at 50% 150%, hsla(339, 49%, 30%, 1) 0, transparent 50%);
}

.text-gradient {
  background: -webkit-linear-gradient(#eee, #333);
  -webkit-background-clip: text;
  -webkit-text-fill-color: transparent;
}

.lds-dual-ring {
  display: inline-block;
}
.lds-dual-ring:after {
  content: ' ';
  display: block;
  width: 64px;
  height: 64px;
  margin: 8px;
  border-radius: 50%;
  border: 6px solid #fff;
  border-color: #fff transparent #fff transparent;
  animation: lds-dual-ring 1.2s linear infinite;
}
@keyframes lds-dual-ring {
  0% {
    transform: rotate(0deg);
  }
  100% {
    transform: rotate(360deg);
  }
}


.blob {
  position: absolute;
  z-index: -10;
  width: 500px;
  height: 500px;
  background: linear-gradient(
    180deg,
    rgba(47, 184, 255,0.5) 31.77%,
    #5c9df1 100%
  );
  mix-blend-mode: normal;
  -webkit-animation: move 1s infinite alternate;
          animation: move 18s infinite alternate;
  transition: 1s cubic-bezier(0.07, 0.8, 0.16, 1);
}

.blob1 {
  position: absolute;
  z-index: -10;
  width: 400px;
  height: 400px;
  background: linear-gradient(
    180deg,
    rgb(52, 172, 166) 31.77%,
    #0a6066 100%
  );
  mix-blend-mode: normal;
  -webkit-animation: move1 1s infinite alternate;
          animation: move1 15s infinite alternate;
  transition: 1s cubic-bezier(0.07, 0.8, 0.16, 1);
}


@-webkit-keyframes move {
  from {
    transform: translate(-100px, -50px) rotate(-90deg);
    border-radius: 24% 76% 35% 65% / 27% 36% 64% 73%;
  }

  to {
    transform: translate(500px, 100px) rotate(-10deg);
    border-radius: 76% 24% 33% 67% / 68% 55% 45% 32%;
  }
}

@keyframes move {
  from {
    transform: translate(-120%, 140%) rotate(-90deg);
    border-radius: 24% 76% 35% 65% / 27% 36% 64% 73%;
  }

  to {
    transform: translate(-120%, -50%) rotate(-90deg);
    border-radius: 76% 24% 33% 67% / 68% 55% 45% 32%;
  }
}

@keyframes move1 {
  from {
    transform: translate(-170%, 200%) rotate(-70deg);
    border-radius: 24% 76% 35% 65% / 27% 36% 64% 73%;
  }

  to {
    transform: translate(-170%, -50%) rotate(-110deg);
    border-radius: 76% 24% 33% 67% / 68% 55% 45% 32%;
  }
}


@media screen and (max-width: 768px) {
  .blob {
    width: 200px;
    height: 200px;
    transform: translate(-25%, -25%);
  }
  
  .blob1 {
    width: 200px;
    height: 200px;
    transform: translate(-25%, -25%);
  }
}

@media screen and (max-width: 480px) {
  .blob {
    width: 100px;
    height: 100px;
    transform: translate(-25%, -25%);
  }
  
  .blob1 {
    width: 100px;
    height: 100px;
    transform: translate(-25%, -25%);
  }
}


@tailwind base;
@tailwind components;
@tailwind utilities;
